<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div id="iiotDeployLogList" fill-height class="mt-6 ml-8 mr-6">
    <the-nerve-table
      :id="'iiotDeployLogListTable'"
      :columns="columns"
      :params="params"
      :empty-state="$t('deployLog.list.emptyList')"
      :is-action-menu-enabled="false"
      :is-row-clickable="canAccess('UI_DEPLOY:LOG_VIEW')"
      :have-access="canAccess('UI_DEPLOY:LOG_VIEW') || canAccess('UI_SUBNAV_DEPLOY_LOG:VIEW')"
      store-module="deploy-log"
      @params-changed="updateParams"
      @row-clicked="(params) => editLog(params.item)"
    >
      <template #additional-actions>
        <v-row>
          <v-col cols="5" lg="3" md="4" sm="4" class="pt-2 d-flex justify-center align-center">
            <v-select
              id="iiotDeployLogListDeploymentSelect"
              ref="select"
              v-model="params.selectedDeploymentType"
              class="type-select"
              :items="[
                { value: 'ALL', text: $t('deployLog.list.all') },
                { value: 'false', text: $t('deployLog.list.deploys') },
                { value: 'true', text: $t('deployLog.list.dryRuns') },
              ]"
              :item-text="deploymentTypes.text"
              :item-value="deploymentTypes.value"
              :placeholder="$t('deployLog.list.deploymentType')"
              attach
              prepend-icon="mdi-filter-outline"
              @change="filterBy()"
            />
          </v-col>
          <v-col cols="5" lg="3" md="4" sm="4" class="pt-2 d-flex justify-center align-center">
            <v-select
              id="iiotDeployLogListWorkloadSelect"
              ref="select"
              v-model="params.selectedWorkloadType"
              class="type-select"
              :items="[
                { value: 'ALL', text: $t('deployLog.list.all') },
                { value: 'vm', text: $t('deployLog.list.vm') },
                { value: 'docker', text: $t('deployLog.list.docker') },
                { value: 'codesys', text: $t('deployLog.list.codesys') },
                { value: 'docker-compose', text: $t('deployLog.list.docker-compose') },
              ]"
              :item-text="workloadTypes.text"
              :item-value="workloadTypes.value"
              :placeholder="$t('workloads.list.workloadType')"
              attach
              @change="filterBy()"
            />
          </v-col>
        </v-row>
        <div />
      </template>
    </the-nerve-table>
  </div>
</template>

<script>
import { TheNerveTable, ProgressLinearWithStatus } from 'nerve-ui-components';
import mqtt from '@/plugins/mqtt';

export default {
  components: {
    TheNerveTable,
  },
  props: {
    page: {
      type: Number,
    },
    itemsPerPage: {
      type: Number,
    },
    search: {
      type: String,
    },
    workloadType: {
      type: String,
    },
    deploymentType: {
      type: [String, Boolean],
    },
  },
  data() {
    return {
      workloadTypes: [
        { value: 'ALL', text: this.$t('deployLog.list.all') },
        { value: 'vm', text: this.$t('deployLog.list.vm') },
        { value: 'docker', text: this.$t('deployLog.list.docker') },
        { value: 'codesys', text: this.$t('deployLog.list.codesys') },
        { value: 'docker-compose', text: this.$t('deployLog.list.docker-compose') },
      ],
      deploymentTypes: [
        { value: 'ALL', text: this.$t('deployLog.list.all') },
        { value: 'false', text: this.$t('deployLog.list.deploys') },
        { value: 'true', text: this.$t('deployLog.list.dryRuns') },
      ],
      params: {
        selectedWorkloadType: '',
        selectedDeploymentType: '',
      },
    };
  },
  computed: {
    columns() {
      return [
        {
          text: this.$t('deployLog.list.headerName'),
          value: 'name',
          icon: 'typeIcon',
          color: 'color',
          component: {
            sfc: () => import('@/components/deploy/deploy-helpers/DeployLogTableIcon.vue'),
          },
          sortable: false,
        },
        {
          text: this.$t('deployLog.list.workloadName'),
          value: 'workloadName',
          sortable: false,
        },
        {
          text: this.$t('deployLog.list.versionName'),
          value: 'versionName',
          sortable: false,
        },
        {
          text: this.$t('deployLog.list.headerProgress'),
          value: 'status',
          component: {
            sfc: ProgressLinearWithStatus,
          },
          sortable: false,
        },
        {
          text: this.$t('deployLog.list.headerFinished'),
          value: 'finished',
          component: {
            sfc: () => import('@/components/shared/nerve-table/TimeOfOperationFinish.vue'),
          },
          sortable: false,
        },
        {
          text: this.canAccess('UI_DEPLOY:LOG_DELETE') ? this.$t('defaultTable.action') : '',
          value: '',
          width: '0%',
          sortable: false,
          component: {
            sfc: () => import('@/components/deploy/deploy-helpers/DeployLogTableActions.vue'),
            props: {
              iconsAction: ['Delete', 'DisabledDelete'],
              fetchAction: () => this.filterBy,
            },
          },
        },
      ];
    },
    getQueryParams() {
      return this.$store.getters['labels/getQuery'];
    },
  },
  async created() {
    this.params = {
      page: this.page || 1,
      itemsPerPage: this.itemsPerPage || 10,
      search: this.search || '',
      selectedWorkloadType: this.workloadType || this.getQueryParams.selectedWorkloadType || '',
      selectedDeploymentType: this.getDeploymentType(),
    };
    await mqtt.subscribeTo('cloud');
    await this.$store.dispatch('node-update-log/setType', { type: 'workload' });
  },
  beforeDestroy() {
    this.$mqtt.unsubscribeFrom('cloud');
  },
  methods: {
    async updateParams(parameters) {
      this.params = {
        ...parameters,
        selectedDeploymentType: this.params.selectedDeploymentType,
        selectedWorkloadType: this.params.selectedWorkloadType,
      };
      this.$router
        .push({
          name: 'Deploy Log List',
          query: {
            page: parameters.page,
            itemsPerPage: parameters.itemsPerPage,
            search: parameters.search || undefined,
            deploymentType: parameters.selectedDeploymentType || undefined,
            workloadType: parameters.selectedWorkloadType || undefined,
          },
        })
        .catch(() => {});
    },
    async filterBy(action) {
      if (action === 'delete') {
        await this.$store.dispatch('deploy-log/fetch', this.params);
      } else {
        this.params.page = 1;
        await this.$store.dispatch('deploy-log/fetch', this.params);
      }
    },
    async editLog(item) {
      await this.$store.dispatch('labels/save_query', this.params);
      this.$router.push({ name: 'Deploy Details', params: { id: item.id } });
    },
    getDeploymentType() {
      if (this.getQueryParams.selectedDeploymentType !== undefined) {
        return this.getQueryParams.selectedDeploymentType;
      }
      if (this.deploymentType !== undefined) {
        return this.deploymentType;
      }
      return '';
    },
  },
};
</script>

<style>
.type-select {
  width: 50%;
}
#iiotDeployLogListDeploymentSelect::placeholder {
  font-size: 14px !important;
}
#iiotDeployLogListWorkloadSelect::placeholder {
  font-size: 14px !important;
}
.docker-btn-color {
  background-image: linear-gradient(to bottom, #ff9a00, #ff6100) !important;
}

.vm-btn-color {
  background-image: linear-gradient(to top, #3810f2, #6f42c1) !important;
}

.codesys-btn-color {
  background-image: linear-gradient(to top, #dc3545, #e83e8c) !important;
}
</style>
